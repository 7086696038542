/* External Libraries */
import { ArrowRightCircleIcon } from '@heroicons/react/24/solid';
/* Components & Widgets */
import moment from 'moment';
import { useRouter } from 'next/router';
import { useEffect, useState } from 'react';

export default function LastViewed() {
  const [list, setList] = useState<any[]>([]);
  const [loading, setLoading] = useState<boolean>(true);
  const router: any = useRouter();

  useEffect(() => {
    fetch(`/api/prop/getLastViewed`)
      .then((res) => {
        return res?.json();
      })
      .then((json) => {
        setList(json);
      })
      .finally(() => {
        setLoading(false);
      });
  }, []);

  if (loading) {
    return (
      <div className="relative">
        <div className="w-full relative flex-col justify-center items-center overflow-y-auto xl:h-[220px] bg-gray-50 mt-3 p-1 py-6 overflow-hidden rounded border border-gray-100 dark:bg-dark-faded dark:border-dark-morefaded">
          <div className="mt-2">
            <button className="flex mx-1 items-center text-xs justify-between group bg-gray-50 w-full even:bg-gray-100 p-2 dark:bg-dark-morefaded">
              <div className="w-[20px] mr-2">
                <ArrowRightCircleIcon className="text-bobo-prop dark:text-dark-prop w-5 h-5 group-hover:text-bobo-prophover dark:group-hover:text-dark-prophover" />
              </div>

              <div className="text-left leading-[14px] text-bobo-prop dark:text-dark-prop group-hover:text-bobo-prophover dark:group-hover:text-dark-prophover">
                <div className="">Hämtar data... Ett ögonblick</div>
              </div>

              <div className="text-gray-400 dark:text-gray-600 font-light text-[12px] leading-[11px] flex-1 text-right"></div>
            </button>
          </div>
        </div>
      </div>
    );
  }

  return (
    <>
      {list && list?.length < 1 ? (
        <div className="flex flex-wrap w-full pb-20 mt-6 border-t border-gray-100 pt-4 dark:border-gray-500">
          <div className="p-4 bg-bobo-proplight dark:bg-dark-faded border border-dashed border-bobo-prop dark:border-dark-prop rounded-md">
            Inga besökta fastigheter registrerade ännu. Kom igång med tjänsten genom att använda
            Fastighetssök eller Avancerad sök.
          </div>
        </div>
      ) : (
        <div className="relative">
          <div className="w-full relative flex-col justify-center items-start lg:items-center overflow-y-auto xl:h-[220px] bg-gray-50 mt-1 lg:mt-3 p-1 py-0 lg:py-6 overflow-hidden rounded border border-gray-100 dark:bg-dark-faded dark:border-dark-morefaded px-3">
            {list
              ?.filter((a) => a?.authority_nickname)
              ?.map((a, idx) => {
                return (
                  <button
                    key={idx}
                    onClick={() => {
                      router.push(`/crm/fastighet/${a?.uuid}/overview`);
                    }}
                    className="flex flex-col lg:flex-row mx-1 items-start lg:items-center text-xs justify-between group bg-gray-50 w-full even:bg-gray-100 p-2 dark:even:bg-dark-faded dark:odd:bg-dark-primary">
                    <div className="flex items-center justify-start">
                      <div className="w-[20px] mr-2">
                        <ArrowRightCircleIcon className="text-bobo-prop dark:text-dark-prop w-5 h-5 group-hover:text-bobo-prophover dark:group-hover:text-dark-prophover" />
                      </div>

                      <div className="text-left leading-[14px] text-bobo-prop dark:text-dark-prop group-hover:text-bobo-prophover dark:group-hover:text-dark-prophover">
                        <div className="">{a?.authority_nickname}</div>
                      </div>
                    </div>

                    <div className="text-gray-400  font-light text-[12px] leading-[11px] flex-1 text-right dark:text-gray-300">
                      Besökt {moment(a?.timestamp * 1000).fromNow()}
                    </div>
                  </button>
                );
              })}
          </div>
          <div className="absolute bottom-0 bg-gradient-to-b from-transparent to-gray-50 dark:to-dark-primary h-0 xl:h-16 z-50 w-full"></div>
        </div>
      )}
    </>
  );
}
