import Link from 'next/link';

import NewsCard from './news-card';

export type NewsItem = {
  image: string;
  category: string;
  title: string;
  preamble: string;
  linkUrl?: string;
  linkText?: string;
  date: string;
  desc?: string | JSX.Element;
};

export default function NewsSection() {
  const currentNews: NewsItem[] = [
    {
      image: '/images/news/hyreskartanupdate.png',
      category: 'Hyreskartan',
      title: 'Uppdateringar med fokus på hyror',
      preamble:
        'Idag lanserar vi nya uppdateringar till tjänsten, med fokus på hyror. Detta är ännu ett steg för oss att öka transparensen kring hur vi räknar, och för att tillgängliggöra data på ett smidigare sätt. Stort tack till alla er som bidragit med feedback och tankar på hur vi hela tiden kan förbättra tjänsten!',
      linkUrl: '/avancerad/hyreskartan',
      date: '2023-12-06',
      desc: (
        <>
          <br />
          &nbsp;&nbsp;* <strong>Hyreskartan</strong> nu med lager- och lätt industrifastigheter
          <br />
          &nbsp;&nbsp;* Utförligare <strong>hyresberäkningar</strong> för bostadsfastigheter
          <br />
          &nbsp;&nbsp;* <strong>Miljöcertifierade</strong> fastigheter
          <br />
          <br />
          <hr className="dark:border-dark-morefaded" />
          <br />
          <strong>Hyreskartan nu med lager- och lätt industrifastigheter</strong>
          <br />
          Hyreskartan innehåller nu tusentals lager och lätt industri-fastigheter med
          hyresinformation på fastighetsnivå, vid sidan av bostäder, kontor och offentliga
          hyresavtal.
          <br />
          <br />
          I hyresanalysen kan du se hyresdata för den aktuella fastigheten i jämförelse med andra
          närliggande fastigheters hyresnivå och få detaljerad information om hyresvärdar och
          hyresgäster i det område du är intresserad av.
          <br />
          <br />
          Som tidigare kan du zooma in på kartan, välja ditt eget område och få omedelbar tillgång
          till marknadsdata. Medelhyra, hyresintervall och detaljerad information om de
          underliggande datapunkterna för det valda området - allt i realtid.
          <br />
          <br />
          <strong>Hur gör vi?</strong>
          <br />
          Vi använder oss av det fastighetsägande bolagets årsredovisning som datakälla. I en
          automatiserad process analyserar vi de olika årsredovisningarna och ägandeförhållande samt
          granskar och filtrerar ut den data som ger en tillförlitlig bild över hyresintäkten för
          den aktuella fastigheten.
          <br />
          <br />
          <hr className="dark:border-dark-morefaded" />
          <br />
          <strong>Utförligare hyresberäkningar för bostadsfastigheter</strong>
          <br />
          Många av våra användare har efterfrågat ännu mer transparens i våra beräkningar kring
          framför allt normhyror, feedback vi älskar!
          <br />
          <br />
          Vi har utökat tjänsten med bakomliggande data, exempelvis de årliga hyresändringarna per
          kommun, ägandebolag och värdeår. Därtill så kan du nu se alla fastigheters normhyra enligt
          -78, -91 samt -12 års metod.
          <br />
          <br />
          <strong>Miljöcertifierade fastigheter</strong>
          <br />
          Nu kan du se om fastigheten är miljöcertifierad och vilken typ av certifiering samt vilken
          nivå fastigheten har erhållit. Vi jobbar med alla de stora aktörerna inom området, såsom
          BREEAM, LEED och Swedish Green Building Council.
          <br />
          <br />
          Förutom de extra funktionerna i systemet kommer du också se att vi har uppdaterat designen
          i stora delar av tjänsten. Vi hoppas att du gillar det!
        </>
      )
    },
    {
      image: '/images/news/hyreskartan.png',
      category: 'Hyreskartan',
      title: 'Hyreskartan 2.0 - nu med dynamisk marknadsdata och filter',
      preamble:
        'Vi har spännande nyheter att dela med er! Vi har precis uppdaterat Hyreskartan med en mängd olika funktioner och gjort den betydligt mer användarvänlig.',
      linkUrl: '/avancerad/hyreskartan',
      date: '2023-09-05',
      desc: (
        <>
          <br />
          <strong>Utforska hyresmarknaden med dynamisk marknadsdata i realtid</strong>
          <br />
          En av de mest spännande nyheterna i den nya versionen av Hyreskartan är möjligheten att
          utforska marknadsdata för hyror på ett helt nytt sätt. Nu kan du enkelt välja ett område
          direkt på kartan som du är intresserad av och få hyresstatistik i <i>realtid</i>. Detta
          ger dig en skräddarsydd vy över hyresinformation i de områden som är mest relevanta för
          dig.
          <br />
          <br />
          <strong>Kraftfulla filter</strong>
          <br />
          Det blir nu ännu enklare att hitta precis det du söker genom att vi har kombinerat
          Hyreskartan med Avancerat sök. Det innebär att du kan filtrera datan och visa
          hyresstatistik över de fastigheter du är intresserad av. Hitta fastigheter som har fått
          investeringsstöd i en viss kommun eller hitta kommunalt ägda kontorsfastigheter och få
          både hyresinformation på ditt urval samt identifiera de aktuella fastigheterna. Kort sagt,
          använd våra filter för att snabbt och enkelt hitta det du behöver.
          <br />
          <br />
          <strong>Mer användarvänlig än någonsin</strong>
          <br />
          Och det bästa av allt, den nya Hyreskartan är mer användarvänlig än någonsin tidigare. Vi
          har lyssnat på era önskemål och gjort det ännu enklare att använda vår tjänst, så att ni
          kan fokusera på det som verkligen är viktigt för er.
          <br />
          <br />
          Vi har ju såklart behållit alla de bra delarna av den tidigare versionen av Hyreskartan.
          Precis som förut kan du se detaljerad hyresinformation på fastighetsnivå för specifika
          objekt du är intresserad av.
        </>
      )
    },
    {
      image: '/images/news/savethedatenews.png',
      category: 'Evenemang',
      title: 'Hyror, Risk & Miljö, Pizza och Prosecco',
      preamble:
        'Fira av sommaren och kick-starta hösten med oss onsdagen den 30:e augusti! Förutom spännande talare och massa trevliga människor kommer det förstås även finnas öl/vin och mat!',
      date: '2023-07-20',
      linkUrl: 'https://fastighetsloggen.confetti.events/signup',
      linkText: 'Anmäl dig kostnadsfritt nu!',
      desc: (
        <>
          <br />
          <strong>Agenda</strong>
          <br />
          <br />
          <strong>Kl: 17.30</strong>
          <br />
          <strong>Välkomna!</strong>
          <br />
          <em>Talare:</em> Per Berggren, Ordförande i Castellum & delägare i Fastighetsloggen
          <br />
          <br />
          <strong>
            Friktion i hyresförhandlingar och bostadsbrist - politikens roll i framtidens
            Fastighetssverige!
          </strong>
          <br />
          <em>Talare:</em> Jennie Nilsson, Riksdagledamot och bostadspolitisk talesperson för
          Socialdemokraterna
          <br />
          <br />
          <strong>Debatt: Två sidor av hyresmarknadens mynt - point of view</strong>
          <br />
          <em>Talare:</em> Stefan Björck, Nationell förhandlare/utredare på Hyresgästföreningen &
          Fredrik Kopsch, Chefsekonom på Timbro
          <br />
          <em>Moderator:</em> Johanna Munck, VD på Strategisk Arkitektur
          <br />
          <br />
          <strong>Status för kontorshyror och kontorsmarknaden</strong>
          <br />
          <em>Talare:</em> Karin Witalis, Head of EMEA research på Colliers
          <br />
          <br />
          <strong>Risk, Miljö & Klimat - Utmaningar och möjligheter med EU-taxonomin</strong>
          <br />
          <em>Talare:</em> TBD (Circle K) & TBD (ICA Fastigheter)
          <br />
          <br />
          <strong>Kl: 18.30</strong>
          <br />
          <strong>Mingel, pizza och prosecco!</strong>
          <br />
          <br />
          <strong>Det finstilta:</strong>
          <br />
          <em>
            - Anmäl dig senast onsdagen den 23:e augusti. Det är begränsat antal platser så om du
            vill att en eller flera kollegor ska delta, vänligen kontakta oss på{' '}
            <Link target="_new" href="mailto:hello@fastighetsloggen.se">
              <span className="text-bobo-prop hover:text-bobo-lightgreen dark:hover:text-bobo-lightgreen dark:text-dark-prop transition-colors duration-300">
                hello@fastighetsloggen.se
              </span>
            </Link>{' '}
            .<br />
            - Du är välkommen från kl 17 och vid 17.30 drar vi igång med agendan som förväntas ta
            cirka en timme. Därefter mingel, pizza och prosecco!
            <br />
            - Du hittar oss på på innergården vid Storgatan 23C (dock inomhus vid dåligt väder).
            <br />- Ifall du inte har möjlighet att medverka på &quot;gratis-event&quot; pga
            företagspolicy så kan du givetvis betala. Kostnaden uppskattas till 200 kronor. Meddela
            oss bara så löser vi det, det viktigaste är att du kommer!
          </em>
          <br />
        </>
      )
    },
    {
      image: '/images/news/almedalen.png',
      category: 'Evenemang',
      title: 'Vi ses i Almedalen!',
      preamble:
        'Dags att stryka linneskjortorna, skaffa bekväma skor och spetsa öronen - med så många bra och intressanta diskussioner kommer Almedalen 2023 bli en höjdpunkt på året.',
      date: '2023-06-26',
      desc: (
        <>
          Vill du få en demo av Fastighetsloggen? Hör av dig till{' '}
          <Link target="_new" href="mailto:hello@fastighetsloggen.se">
            <span className="text-bobo-prop hover:text-bobo-lightgreen dark:hover:text-bobo-lightgreen dark:text-dark-prop transition-colors duration-300">
              hello@fastighetsloggen.se
            </span>
          </Link>{' '}
          så ses vi där!
        </>
      )
    },
    {
      image: '/images/news/bolagssoknews.png',
      category: 'Avancerad sök',
      title: 'Bolagssök och koncerners fastighetsbestånd',
      preamble:
        'Många av er har efterfrågat möjligheten att identifiera de fastigheter ett bolag eller en koncern äger. Nu är funktionen på plats!',
      linkUrl: '/avancerad/sok',
      date: '2023-05-31',
      desc: (
        <>
          Få information om ett individuellt bolags bestånd, en del av koncernens bestånd eller hela
          koncernens fastighetsinnehav inför transaktion eller fördjupande kunskap om ett specifikt
          fastighetsbolag.
          <br />
          <br />
          Förutom att hitta de fastigheter ett bolag/koncern äger kan du även filtrera vidare,
          exempelvis hitta de skolor koncernen äger som ligger i Skellefteå. Resultatet presenteras
          såklart i kartvy eller i en lista. Funktionen hittar du under Avancerat Sök.
          <br />
          <br />
          Du får även information om koncernstrukturer, hur ägarförhållandena ser ut mellan bolagen,
          bolagens individuella fastighetsbestånd samt finansiella nyckeltal.
          <br />
          <br />
          M2 Gruppen aviserade nyligen att dom ska avyttra fastigheter i Stockholm. Vilka är dessa
          och vad är hyresintäkterna?
        </>
      )
    },
    {
      image: '/images/news/avanceradsoknews.png',
      category: 'Avancerad sök',
      title: 'Avancerat sök - Fler filter och snabbare, i kartmiljö',
      preamble:
        'Vi har nu lanserat en stor uppgradering av Avancerat Sök som innehåller betydligt fler filter och prestandan har även optimerats (den är alltså snabb!).',
      linkUrl: '/avancerad/sok',
      date: '2023-05-17',
      desc: (
        <>
          Använd funktionen för att identifiera fastigheter inför transaktion, hitta
          jämförelseobjekt inför hyresförhandling (likvärdiga fastigheter) eller fastigheter där
          specifika hyresgäster finns.
          <br />
          <br />
          Sök fastigheter efter dina kriterier genom att använda nya filter såsom normhyra,
          lokalhyra, boa/loa, hyresgäster, om fastigheten har fått investeringsstöd, ägandeform,
          etc. De filter som redan fanns ligger såklart kvar!
          <br />
          <br />
          Ovanstående bild visar de 2628 fastigheterna med en normhyra högre än 2000 kronor i
          Sverige. Undrar du om några av dessa också har fått investeringsstöd?
        </>
      )
    },
    {
      image: '/images/news/tentaclenews.png',
      category: 'Kundcase',
      title: 'Tentacle väljer Fastighetsloggen',
      preamble: 'Ännu ett spännande bolag väljer Fastighetsloggen - välkomna Tentacle!',
      date: '2023-05-15',
      desc: (
        <>
          <Link target="_new" href="https://www.linkedin.com/company/tentatech/">
            <span className="text-bobo-prop hover:text-bobo-lightgreen dark:hover:text-bobo-lightgreen dark:text-dark-prop transition-colors duration-300">
              Tentacle
            </span>
          </Link>{' '}
          är Sveriges största plattform och rådgivare för transaktioner av kommersiella fastigheter,
          flerfamiljshus och byggrätter/mark. Genom Tentacle-plattformen kan fastighetsägare anonymt
          och förutsättningslöst ta reda på intresset för fastigheter/portföljer och identifiera
          potentiella köpare till dessa. Bolagets unika teknologi sköter en stor del av
          försäljningsprocessen vilket gör att deras rådgivare kan optimera sin tid där den gör mest
          skillnad för dess kunder.
          <br />
          <br />
          &quot;Fastighetsloggen ger oss enkelt tillgång till unik data vi inte hittar någon
          annanstans vilket gör att vi kan erbjuda ännu bättre tjänster till våra kunder. Vi tycker
          att Fastighetsloggen är ett jättespännande bolag med stor potential att skapa banbrytande
          tjänster.&quot;
          <br />- Philippe Gremillet - VD & Medgrundare, Tentacle
        </>
      )
    },
    {
      image: '/images/news/vardeindikationnews.png',
      category: 'Värdeindikation',
      title: 'Värdeindikation i samarbete med Cushman & Wakefield',
      preamble: 'I samarbete med Cushman & Wakefield har vi lanserat tjänsten Värdeindikation.',
      date: '2023-04-04',
      desc: (
        <>
          Genom att kombinera Fastighetsloggens automatiserade datainsamling med Cushman &
          Wakefields långa erfarenhet av fastighetsvärderingar erbjuder vi nu en indikation av
          fastighetens värde snabbare, och till ett mer konkurrenskraftigt pris, än andra produkter
          på marknaden.
          <br />
          <br />
          En Värdeindikation beställs enkelt via Fastighetsloggens tjänst och priset för
          indikationen ser du innan du lägger beställningen. Efter det att en auktoriserad
          fastighetsvärderare på Cushman & Wakefield har analyserat fastigheten, och marknaden den
          befinner sig på, får du som användare indikativa nyckeltal för direktavkastningskrav,
          hyresnivå, drift- och underhållskostnader samt driftnetto vilket resulterar i en
          värdeindikation för fastigheten direkt via Fastighetsloggen. De fastighetstyper som för
          närvarande stöds är alla hyreshusenheter (300-serien) samt industrienheter (400-serien)
          med undantag för tomtmark (typ 310 och 411).
          <br />
          <br />
          Du hittar tjänsten genom att välja den fastighet du är intresserad av, gå till
          Fastighetskortet och sedan gå till &quot;Värdeindikation&quot; som du hittar uppe till
          höger i menyn.
        </>
      )
    }
  ];

  return (
    <div className="mt-4 relative">
      <div className="w-full h-2" />
      <div className="text-[20px] font-normal text-gray-700 dark:text-white leading-[20px]">
        Senaste från Fastighetsloggen
      </div>
      <div className="w-full min-h-[300px] xl:max-h-[calc(100vh-580px)] overflow-y-auto relative z-10 mt-5 pb-10 bg-gray-50 p-4 rounded-md border-gray-100 border dark:bg-dark-lessfaded dark:border-dark-morefaded">
        {currentNews?.map((newsItem, idx) => {
          return <NewsCard key={idx} newsItem={newsItem} />;
        })}
      </div>
      <div className="absolute bottom-0 bg-gradient-to-b from-transparent to-gray-50 dark:to-dark-primary h-0 xl:h-16 z-50 w-full"></div>
    </div>
  );
}
