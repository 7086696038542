import { ArrowRightCircleIcon, EllipsisHorizontalIcon } from '@heroicons/react/24/solid';
import cls from 'classnames';
import { motion } from 'framer-motion';
import Image from 'next/image';
import { useRouter } from 'next/router';
import { useState } from 'react';

import { NewsItem } from './news-section';

const LinkButton = ({
  url,
  category,
  linkText
}: {
  url: string;
  category: string;
  linkText?: string;
}) => {
  const router = useRouter();

  const handleClick = (e) => {
    e.preventDefault();
    e.stopPropagation();
    router.push(url);
    close();
  };

  return (
    <button
      onClick={handleClick}
      className="flex mb-1 items-center transition-colors duration-300 text-bobo-prop dark:text-dark-prop hover:text-bobo-prophover dark:hover:text-white dark:bg-dark-morefaded rounded-md p-4 py-0">
      <ArrowRightCircleIcon className="w-5 h-5" />

      <div className="text-left leading-3 border-transparent p-2 text-xs cursor-pointer inline-flex items-center font-medium">
        {linkText ? linkText : `Utforska ${category}`}
      </div>
    </button>
  );
};

export default function NewsCard({ newsItem }: { newsItem: NewsItem }) {
  const [open, setOpen] = useState<boolean>(false);
  const [showText, setShowText] = useState<boolean>(false);

  function sleep(time: number) {
    return new Promise((resolve) => setTimeout(resolve, time));
  }

  const handleClick = async () => {
    setOpen(!open);

    if (!open) {
      setShowText(true);
    } else if (open) {
      await sleep(300).then(() => {
        setShowText(false);
      });
    }
  };

  return (
    <motion.div
      className={cls(
        `w-full relative overflow-hidden border rounded-md shadow bg-white hover:bg-bobo-proplight dark:hover:border-dark-prophover flex-col mb-2 transition-colors duration-300 dark:bg-dark-faded`,
        {
          'border-gray-100 dark:border-dark-morefaded': !open
        },
        {
          'border-gray-200 dark:border-dark-morefaded': open
        }
      )}
      initial={{ height: 200 }}
      animate={{ height: open ? 'auto' : 200, transition: { duration: 0.3 } }}
      exit={{ height: 200, transition: { duration: 0.3 } }}
      onKeyDown={handleClick}
      tabIndex={0}
      role={newsItem.desc ? 'button' : ''}
      onClick={handleClick}>
      <div className="flex">
        <div
          className={cls(
            `overflow-hidden relative transition-all duration-300 shadow`,
            { 'w-[150px] h-[200px]': !open },
            { 'w-full h-[240px]': open }
          )}>
          <Image
            className="mr-5 mb-1 opacity-80 object-cover"
            src={newsItem.image}
            alt={'Illustration'}
            fill
          />
        </div>

        <div
          className={cls(
            'flex flex-col justify-between py-1 flex-1 w-full transition-all duration-300 overflow-hidden',
            { 'w-0': open }
          )}>
          <div className="p-3">
            <div className="flex justify-between border-b dark:border-dark-morefaded pb-2 border-gray-100 items-end">
              <div className="flex flex-col w-full leading-[10px]">
                <div className="uppercase text-bobo-black text-mini font-medium dark:text-white ">
                  {newsItem.category}
                </div>

                <div className="text-bobo-prop dark:text-dark-prop text-sm font-medium">
                  {newsItem.title}
                </div>

                <div className="flex text-gray-400 dark:text-gray-300 text-mini font-normal justify-between italic">
                  <div className="">Publicerad: {newsItem.date}</div>
                </div>
              </div>
            </div>

            <div className="text-xs text-gray-700 dark:text-white font-medium mt-2">
              {!open && !showText && newsItem.preamble}
            </div>
          </div>

          <div className="w-full flex justify-between px-3 -mt-3">
            {newsItem.desc && !showText ? (
              <EllipsisHorizontalIcon className="text-gray-400 h-6 w-6 hover:text-gray-600 dark:hover:text-white transition-colors duration-300" />
            ) : (
              <div />
            )}
            {newsItem.linkUrl && !showText ? (
              <LinkButton
                url={newsItem.linkUrl}
                category={newsItem.category}
                linkText={newsItem.linkText}
              />
            ) : (
              <div />
            )}
          </div>
        </div>
      </div>

      {showText && newsItem.desc ? (
        <>
          <div className="flex flex-col w-full leading-[10px] p-3">
            <div className="uppercase text-bobo-black text-mini font-medium dark:text-white">
              {newsItem.category}
            </div>

            <div className="text-bobo-prop dark:text-dark-prop text-sm font-medium">
              {newsItem.title}
            </div>

            <div className="flex text-gray-400 dark:text-gray-300 text-mini font-normal justify-between italic">
              <div className="">Publicerad: {newsItem.date}</div>
            </div>
          </div>

          <div className="p-3 text-xs dark:text-white text-bobo-black">
            <p className="mb-2">{newsItem.preamble}</p>
            <p>{newsItem.desc}</p>
          </div>

          {newsItem.linkUrl ? (
            <div className="w-full flex justify-end p-3">
              <LinkButton
                url={newsItem.linkUrl}
                category={newsItem.category}
                linkText={newsItem.linkText}
              />
            </div>
          ) : null}
        </>
      ) : null}
    </motion.div>
  );
}
