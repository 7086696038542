/* External Libraries */
import Image from 'next/image';
import { useTranslation } from 'next-i18next';

/* Components & Widgets */
import LastViewed from './items/last-viewed';
import NewsSection from './items/news-section';
import PropSection from './items/prop-section';

export default function DashboardIndex() {
  const { t } = useTranslation('common');

  return (
    <div className="bg-white text-xs lg:text-sm w-full dark:bg-dark-primary relative z-20 border-b border-gray-100">
      <div className="flex flex-col xl:flex-row px-6 lg:px-14 space-x-0 xl:space-x-8 mb-8 justify-around">
        <div className="max-w-[max-content] relative z-10 rounded p-0 pt-4 mt-8 hidden lg:block">
          <PropSection />
          <div className="relative h-0 w-0 xl:h-[450px] xl:w-full">
            <Image
              src={`/images/illustrations/dataroom.svg`}
              alt={t('pages.dashboard.image-alt')}
              fill
            />
          </div>
        </div>
        <div className="w-full xl:w-1/2 relative z-10 rounded pt-4 mt-8 dark:bg-dark-primary">
          <div className="relative z-10 w-full mb-5 dark:bg-dark-primary">
            <div className="text-[20px] font-normal text-gray-700 dark:text-white leading-[20px] mb-6 dark:bg-dark-primary">
              {t('navigation.crm.heading-recently-viewed')}
            </div>
            <LastViewed />
          </div>
          <div className="hidden lg:block">
            <NewsSection />
          </div>
        </div>
      </div>
    </div>
  );
}
