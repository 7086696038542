import { useUser, withPageAuthRequired } from '@auth0/nextjs-auth0';
import cls from 'classnames';
import { getCookie } from 'cookies-next';
/* External Libraries */
import Head from 'next/head';
import { useTranslation } from 'next-i18next';
import { useEffect, useState } from 'react';
import { useGlobal } from 'reactn';
import { Actor } from 'shared/dist/types';
/* Internal libraries */
import Container from 'src/layout/container';

import { makeStaticProps } from '@/src/api/i18nUtil';
import DashboardIndex from '@/src/components/dashboard/overview';
import { getCompanyTeam } from '@/src/lib/customer-client-wrapper';
import LoadingModal from '@/src/widgets/loading-dialog';

// import { usePiwikPro } from '@piwikpro/next-piwik-pro';

export default withPageAuthRequired(function IndexHome({
  user,
  index
}: {
  user: any;
  index: string;
}) {
  const { isLoading } = useUser();
  const [loading, setLoading] = useState<boolean>(true);
  const [teamMembers, setTeamMembers] = useState<any[]>();
  const [actor] = useGlobal<{ activeActor: Actor }>('activeActor' as never);
  const { t } = useTranslation('common');

  // const { PageViews } = usePiwikPro();
  // useEffect(() => {
  //   // PageViews.trackPageView('homepage');
  // }, []);

  let isFetching = false;

  const fetchData = async () => {
    // setLoading(true);
    // await getCRMBoards(0)
    //   .then((data) => {
    //     setBoards([...data]);
    //   })
    //   .finally(() => {
    //     setLoading(false);
    //     isFetching = false;
    //   });
    setLoading(false);
  };

  useEffect(() => {
    if (actor) {
      if (!isFetching) {
        setLoading(true);
        isFetching = true;
        fetchData();
        getCompanyTeam(actor).then((teamData) => {
          setTeamMembers(teamData?.members);
        });
      }
    }
  }, [actor]);

  const name: string = actor?.company_name ?? '';

  if (isLoading) {
    return (
      <Container>
        <div>{t('general.loading')}</div>
      </Container>
    );
  }

  if (!actor) {
    return <Container />;
  }

  const mod: JSX.Element = <DashboardIndex />;
  const mapOnly = true;

  return (
    <Container
      title={actor?.company_name}
      section={name}
      header={true}
      tabsRight={null}
      hideTabMenu={true}
      subTitle={<span>&nbsp;</span>}
      mapOnly={mapOnly}
      bgClass="!bg-gray-50 dark:!bg-dark-lessfaded"
      subHeader={false}>
      <Head>
        <title></title>
      </Head>

      <div>
        <div
          className={cls(
            'bg-gray-50 dark:bg-dark-primary relative overflow-hidden min-h-info',
            { '': !mapOnly },
            { 'min-h-maponly': mapOnly }
          )}>
          {loading ? <LoadingModal isLoading={loading} /> : mod}
        </div>
      </div>
    </Container>
  );
});

export async function getStaticProps({ locale }) {
  return makeStaticProps(locale, ['common', 'shared']);
}
