/* External Libraries */
import toast from 'react-hot-toast';
import { Actor, CustomerSubscriptionInterval, CustomerSubscriptionPlan } from 'shared/dist/types';
import { getActiveActor } from 'src/lib/user-client-wrapper';

export async function requestDelete() {
  toast.loading(
    'Skickar in önskan att radera all din personliga data från Fastighetsloggen, ett ögonblick...'
  );
}

export async function getSubscription(data) {
  const toastId = toast.loading('Hämtar bolagets prenumeration, ett ögonblick...');

  const url = `/api/customer/getSubscription`;
  const body = JSON.stringify(data);
  return fetch(url, {
    method: 'post',
    headers: {
      'Accepted-Header': 'application/json',
      'Content-Type': 'application/json'
    },
    body
  })
    .then((res) => {
      return res.json();
    })
    .then((json) => {
      toast.dismiss();
      return json;
    })
    .catch((err) => {
      toast.error(
        `Ajdå, något gick fel när vi skulle hämta bolagets prenumeration. Försök igen eller kontakta oss på support. [${err.message}]`,
        {
          id: toastId
        }
      );
      return {
        error: true,
        msg: `Failed to fetch url: ${url}`
      };
    });
}

export async function getInvoices(data) {
  const toastId = toast.loading('Hämtar bolagets fakturor, ett ögonblick...');

  const url = `/api/customer/getInvoices`;
  const body = JSON.stringify(data);

  return fetch(url, {
    method: 'post',
    headers: {
      'Accepted-Header': 'application/json',
      'Content-Type': 'application/json'
    },
    body
  })
    .then((res) => {
      return res.json();
    })
    .then((json) => {
      toast.dismiss();
      return json;
    })
    .catch((err) => {
      toast.error(
        `Ajdå, något gick fel när vi skulle hämta bolagets fakturor. Försök igen eller kontakta oss på support. [${err.message}]`,
        {
          id: toastId
        }
      );
      return {
        error: true,
        msg: `Failed to fetch url: ${url}`
      };
    });
}

export async function getCompanyTeam(data) {
  const toastId = toast.loading('Hämtar bolagets användare, ett ögonblick...');

  const url = `/api/customer/getTeamMembers`;
  const body = JSON.stringify(data);

  return fetch(url, {
    method: 'post',
    headers: {
      'Accepted-Header': 'application/json',
      'Content-Type': 'application/json'
    },
    body
  })
    .then((res) => {
      return res.json();
    })
    .then((json) => {
      toast.dismiss();
      return json;
    })
    .catch((err) => {
      toast.error(
        `Ajdå, något gick fel när vi skulle hämta dina uppgifter. Försök igen eller kontakta oss på support. [${err.message}]`,
        {
          id: toastId
        }
      );
      return {
        error: true,
        msg: `Failed to fetch url: ${url}`
      };
    });
}

export async function updateSubscription(
  activeActor: Actor | null,
  interval: CustomerSubscriptionInterval,
  plan: CustomerSubscriptionPlan,
  invoiceEmail: string,
  invoiceRef: string
) {
  const body = JSON.stringify({ activeActor, interval, plan, invoiceEmail, invoiceRef });

  return fetch(`/api/customer/updateSubscription`, {
    method: 'post',
    headers: {
      'Accepted-Header': 'application/json',
      'Content-Type': 'application/json'
    },
    body
  })
    .then((res) => {
      return res.json();
    })
    .then((json) => {
      return json?.updActor;
    })
    .catch((err) => {
      console.log(err);
      return null;
    });
}

export async function addUpdateMember(newData, orgData, deactivate = false, activate = false) {
  const toastId = toast.loading(
    `${
      orgData
        ? 'Uppdaterar ditt team, ett ögonblick...'
        : 'Bjuder in till ditt team, ett ögonblick...'
    }`
  );

  const activeActor = getActiveActor();

  const body = JSON.stringify({
    newData,
    orgData,
    activeActor,
    permission: newData?.permission,
    deactivate,
    activate
  });

  return fetch(`/api/customer/addUpdateMember`, {
    method: 'post',
    headers: {
      'Accepted-Header': 'application/json',
      'Content-Type': 'application/json'
    },
    body
  })
    .then((res) => {
      return res.json();
    })
    .then((json) => {
      toast.success(
        orgData
          ? 'Nu har vi uppdaterat ditt team!'
          : 'Nu har vi skickat en inbjudan till din kollega.',
        {
          id: toastId
        }
      );
      return json;
    })
    .catch((err) => {
      toast.error(
        `Ajdå, något gick fel när vi skulle uppdatera din sessions-data. Försök igen eller kontakta oss på support. [${err.message}]`,
        {
          id: toastId
        }
      );
      return null;
    });
}

export async function addNewMember(newData, orgData, actor, deactivate = false, activate = false) {
  const toastId = toast.loading(
    `${
      orgData
        ? 'Uppdaterar ditt team, ett ögonblick...'
        : 'Bjuder in till ditt team, ett ögonblick...'
    }`
  );

  const body = JSON.stringify({
    newData,
    orgData: null,
    addNewCo: true,
    activeActor: actor,
    permission: newData?.permission,
    deactivate,
    activate
  });

  return fetch(`/api/customer/addUpdateMember`, {
    method: 'post',
    headers: {
      'Accepted-Header': 'application/json',
      'Content-Type': 'application/json'
    },
    body
  })
    .then((res) => {
      return res.json();
    })
    .then((json) => {
      toast.success(
        orgData
          ? 'Nu har vi uppdaterat ditt team!'
          : 'Nu har vi skickat en inbjudan till din kollega.',
        {
          id: toastId
        }
      );
      return json;
    })
    .catch((err) => {
      toast.error(
        `Ajdå, något gick fel när vi skulle uppdatera din sessions-data. Försök igen eller kontakta oss på support. [${err.message}]`,
        {
          id: toastId
        }
      );
      return null;
    });
}

export async function getTeamMembers(actor) {
  return fetch('/api/customer/getTeamMembers', {
    method: 'POST',
    headers: { 'content-type': 'application/json' },
    body: JSON.stringify({ actor_uuid: actor?.actor_uuid })
  })
    .then((res) => {
      return res.json();
    })
    .catch((error) => {
      console.error(error);
      toast.error('Error! Försök igen eller kontakta oss på support.');
    });
}
